.Footer {
  width: 100%;
  background-color: var(--white);
  /* color: var(--white); */
  /* margin-top: 1rem; */
  padding: 1.5rem 1rem;
}

.Footer--content {
  display: flex;
  flex-flow: wrap;
  flex-direction: column;
  height: 100%;
  max-width: 1640px;
  width: 100%;
  margin: 0 auto;
}

.Footer--content > * {
  line-height: 1rem;
  display: inline-block;
}

@media(min-width: 64em) {
  .Footer--content {
    flex-direction: row;
  }
}
