.Layout {
  position: relative;
  margin: 0 auto;
  /* max-width: 1640px; */
  width: 100%;
  /* padding: 1rem 0; */
  min-height: calc(100vh - 4rem);
}

.layout--internal {
  /* padding: 1rem 0; */
  max-width: 1364px;
  width: 100%;
  margin: 0 auto;
}

.main {
  background-color: var(--white);
  /* background-image: url(/background-full.webp); */
  background-attachment: fixed;
  background-size: cover;
}


.section {
  /* min-height: 27rem; */
  padding: 4.5rem 0;
}

.program-section {
  background-color: var(--blue);
}


.about-section__content { 
  margin-left: auto;
}

.about-section h2 {
  color: var(--blue);
}

.program-section,
.who-section,
.offer-section,
.ready-section,
.quiz-section {
  align-items: center;
  /* min-height: 25rem; */
}


.program-section__content,
.about-section__content {
  max-width: 28rem;
}





/* .program-section__description,
.who-section__description,
.offer-section__description,
.ready-section__description {
  max-width: 60rem;
  padding: 1rem;
} */

.program-section__description h1,
.who-section__description h1,
.offer-section__description h1,
.ready-section__description h1 {
  text-transform: uppercase;
}

.program-section {
  display: flex;
  flex-direction: row;
}

.quiz-section {
  background-color: var(--navy-200);
  color: var(--white);
  display: flex;
  flex-direction: row;
  text-align: center;
}



@media(min-width: 64em) {
  .program-section {
    background-image: url('/images/people.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 8rem 0;
  }
}

@media(min-width: 64em) {
  .about-section {
    background-image: url(/images/person-on-phone.png);
    background-repeat: no-repeat;
    background-size: contain;
  }

  .about-section__content {
    background-color: #fff;
    padding: 2rem;
  }

  .about-section {
    padding: 3rem 0;
  }
}






.program-section__image {
  max-width: 100%;
  height: auto;
  max-height: 25rem;
  min-width: 15rem;
}

.action {
  width: fit-content;
}

.who-section {
  background-position: right;
  display: flex;
  flex-direction: row;
  background-image: url(/images/woman-looking-train.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
}

.who-section__description {
  background: linear-gradient(270deg, rgb(255, 255, 255, 0) 20%, rgb(232, 232, 237, 255) 50%);
  padding: 2rem;
  padding-right: 10rem;
  border-radius: 3rem;
}

.offer-section {
  display: flex;
  flex-direction: row-reverse;
}

.offer-section__description {
  max-width: 50rem;
}

.offer-section__image {
  height: auto;
  max-height: 25rem;
  min-width: 15rem;
  height: 100%;
  width: 69%;
}

.ready-section {
  display: flex;
  justify-content: center;
  background-image: url(/background-full.webp);
  background-attachment: fixed;
  border: 1rem solid var(--white);
  border-radius: 5rem;
}

.ready-section__description {
  width: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--white);
  /* text-align: center; */
}

.ready-section__description > h1 {
  color: var(--white);
}

@media screen and (max-width: 34.999em) {
  .program-section__image,
  .offer-section__image {
    display: none;
  }
  .who-section__description {
    height: 100%;
    background: rgb(232, 232, 237, 0.8);
    border-radius: 0;
    padding: 1rem;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 25rem;
  }
}

.color-yellow {
  color: var(--yellow);
}
.color-aqua {
  color: var(--aqua);
}

.uppercase {
  text-transform: uppercase;
}

