.Header {
  background-color: var(--white);
}

.Header--Container {
  position: relative;
  max-width: 2000px;
  margin: 0 auto;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  width: 90%;
}

.Header--Logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Header--help {
  bottom: 4rem;
  position: fixed;
  right: 0;
  transform: translateX(-50%);
  z-index: 2;
}

.Header--help .Icon--wrapper {
  border: 5px solid var(--white);
  background-color: var(--blue);
}
