.SiteMenu {
  position: fixed;
  right: 0;
  z-index: 100;
  width: 16rem;
}

.SiteMenu--activator {
  position: absolute;
  right: 1rem;
}

.SiteMenu--LogoutButton {
  display: block;
  margin: 0 auto;
}

.SiteMenu--Icon {
  transform: translateY(3px);
  display: inline-block;
  margin-right: 1rem;
}

.SiteMenu__ul {
  list-style: none;
}

.SiteMenu__li {
  align-items: center;
  display: flex;
  margin-left: 3.5rem;
}

.SiteMenu__a {
  border-bottom: 2px solid transparent;
  display: flex;
  flex: 1;
  text-decoration: inherit;
  color: var(--blue);
  font-family: europa, sans-serif;
  font-weight: 400;
  padding: 1.25rem 0;
  transition: 0.15s ease-in-out;
}

.SiteMenu__a:hover {
  border-color: 2px solid var(--blue);
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.Spinner {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: rotate;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: rgba(255, 255, 255, 0.8);
  display: inline-block;
  height: 1rem;
  width: 1rem;
}

.SiteMenu_spinner-mobile-background {
  background-color: var(--navy-200);
}

.SiteMenu__print-icon-container {
  background-color: var(--blue);
  padding: 0.48rem 0.45rem 0.1rem 0.55rem;
  border-radius: 50%;
}

.SiteMenu__print-icon-container:hover {
  background-color: var(--blue-200);
}

.SiteMenu__img {
  filter: brightness(0) invert(1);
  max-width: 1.15rem;
  height: auto;
}

.SiteMenu__img-mobile {
  max-width: 0.8rem;
}

.SiteMenu__print-icon-container,
.SiteMenu__print-icon-container-mobile {
  cursor: pointer;
}
