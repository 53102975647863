@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.Wrapper {
  align-items: center;
  background-color: rgba(1, 1, 1, 0.4);
  color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 1;
  position: fixed;
  top: 0;
  transition: background-color 0.5s ease-in-out;
  width: 100%;
  z-index: 9999;
}

.Spinner {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: rotate;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: rgba(255, 255, 255, 0.8);
  display: inline-block;
  height: 3rem;
  width: 3rem;
}
